import {
    InteractionRequiredAuthError,
    InteractionStatus
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Button, Chip, ChipProps, LinearProgress, Menu, MenuItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
    DataGridPro,
    GridColDef,
    GridFilterModel,
    GridMoreVertIcon,
    GridRenderCellParams,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { loginRequest } from '../authConfig';
import { callGetModule, callGetModulesSearch } from '../services/dashboardService';
import { Connector } from '../types/Connector';
import { DeploymentRecord } from '../types/DeploymentRecord';
import { checkAuthorization } from './Authorized';
import ConnectorCreateModule from './ConnectorCreateModule';
import ConnectorDialog from './ConnectorDialog';
import DeploymentDialogCreateDeployment from './DeploymentDialogCreateDeployment';

export default function ConnectorDataGrid(props: { setOpenError: (open: boolean) => void; }) {
    const { setOpenError } = props;

    function getChipProps(params: GridRenderCellParams): ChipProps {
        switch (params.value) {
            case "Pending":
                return {
                    label: "Pending",
                    color: "warning"
                };
            case "InProgress":
                return {
                    label: "InProgress",
                    color: "primary"
                };
            case "Succeeded":
                return {
                    label: "Succeeded",
                    color: "success"
                };
            case "Failed":
                return {
                    label: "Failed",
                    color: "error"
                };
            case true:
                return {
                    label: "Yes",
                    style: {
                        backgroundColor: "#90CAF9",
                        color: "#000000"
                    }
                };
            case false:
                return {
                    label: "No",
                    color: "secondary"
                };
            default:
                return {
                    label: params.value
                };
        }
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'name', headerName: 'Connector', width: 200 },
        { field: 'deviceName', headerName: 'Device Name', width: 150 },
        { field: 'moduleType', headerName: 'Application Type', width: 200 },
        { field: 'ioTHubName', headerName: 'IoT Hub', width: 175 },
        { field: 'environment', headerName: 'Environment', width: 175 },
        {
            field: 'dateTimeApplied', headerName: 'Deployment Date', width: 160, type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value).toISOString().slice(0, -5)
        },
        { field: 'version', headerName: 'Version', width: 75 },
        { field: 'assignedBy', headerName: 'Assigned By', width: 100 },
        {
            field: 'status', headerName: 'Status', width: 75, renderCell: (params) => {
                return <Chip size="small" {...getChipProps(params)} />;
            }
        },
        { field: 'statusMessage', headerName: 'Status Message' },
        {
            field: 'isAutoDeployment', headerName: 'Auto Deploy', width: 100, renderCell: (params) => {
                return <Chip size="small" {...getChipProps(params)} />;
            }
        },
        { field: 'platform', headerName: 'Platform' },
        {
            field: 'isSynced', headerName: 'Synced', width: 70, renderCell: (params) => {
                return <Chip size="small" {...getChipProps(params)} />;
            }
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            filterable: false,
            width: 75,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            id="connector-actions-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            startIcon={<GridMoreVertIcon />}
                        />
                        <Menu
                            id="connector-actions-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseRowMenu}
                            MenuListProps={{
                                'aria-labelledby': 'connector-actions-button-item',
                            }}
                        >
                            <MenuItem onClick={handleCreateDeployment} disabled={!isAuthorized}>Create Deployment</MenuItem>
                            <MenuItem onClick={handleUpdateConfigurationActionClick} disabled={!isAuthorized}>Update Configuration</MenuItem>
                            <MenuItem onClick={handleViewDeploymentsActionClick}>View Deployments</MenuItem>
                        </Menu>
                    </Box>
                );
            }
        }
    ];

    // Extract filter module from state if any
    // This filter can be passed from Deployments view
    const { state } = useLocation();
    const filterModule = state as DeploymentRecord;
    const defaultFilter = state
        ? {
            items: [
                {
                    columnField: 'id',
                    operatorValue: 'equals',
                    value: filterModule?.moduleId,
                },
            ],
        }
        : undefined;

    const [searchParams] = useSearchParams();
    const deviceSearch = searchParams.get('deviceName') || '';

    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(defaultFilter);
    const [filterModuleId, setFilterModuleId] = useState<string | null>(filterModule?.moduleId);
    const [filterConnectorName, setFilterConnectorName] = useState<string>();
    const [filterDeviceName, setFilterDeviceName] = useState<string>(deviceSearch);

    const { instance, inProgress, accounts } = useMsal();
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [rowCount, setRowCount] = useState<number>(0);
    const [apiData, setApiData] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [selectedConnector, setSelectedConnector] = useState<Connector>({} as Connector);
    const [openDialogCreateDeployment, setOpenDialogCreateDeployment] = useState<boolean>(false);
    const [openDialogCreateModule, setOpenDialogCreateModule] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleRowClick = (rowData: Connector) => {
        setSelectedConnector({ ...rowData });
    };

    const handleViewDeploymentsActionClick = () => {
        handleCloseRowMenu();
        navigate("/deployments", { state: selectedConnector });
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseRowMenu = () => {
        setAnchorEl(null);
    };

    const handleUpdateConfigurationActionClick = () => {
        handleCloseRowMenu();
        setOpenEditDialog(true);
    };

    const handleRowDoubleClick = (rowData: Connector) => {
        setSelectedConnector({ ...rowData });
        if (isAuthorized) {
            setOpenEditDialog(true);
        }
    };

    const closeDialogHandler = useCallback(() => {
        setOpenEditDialog(false);
    }, []);

    const saveHandler = useCallback((change: boolean) => {
        if (change) {
            // To trigger data grid refresh after saved
            setApiData(null);
        }
    }, []);

    const handleCreateDeployment = () => {
        handleCloseRowMenu();
        setOpenDialogCreateDeployment(true);
    };

    const handleCloseDialogCreateDeployment = useCallback(() => {
        setOpenDialogCreateDeployment(false);
    }, []);

    const handleCreateModule = () => {
        setOpenDialogCreateModule(true);
    };

    const handleCloseDialogCreateModule = useCallback(() => {
        setOpenDialogCreateModule(false);
    }, []);

    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        const getModuleRows = async () => {
            // Already loaded or loading
            if (apiData || inProgress !== InteractionStatus.None) {
                return;
            }

            const accessTokenRequest = {
                scopes: loginRequest.scopes,
                account: accounts[0],
            };

            try {
                setLoading(true);

                checkAuthorization(setIsAuthorized, accounts);

                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                // Call your API with token
                if (filterModuleId) {
                    const response = await callGetModule(accessToken, filterModuleId);
                    let data: any = [response.data];
                    setApiData(data);
                    setRowCount(1);
                    setLoading(false);
                } else {
                    const response = await callGetModulesSearch(accessToken, pageSize, page, filterConnectorName, undefined, undefined, filterDeviceName);
                    let responseObject = response.data;
                    const connectors = responseObject.items;

                    // Remove archived connectors
                    const currentConnectors = connectors.filter((connector: any) => connector.isArchived === false);
                    setApiData(currentConnectors);
                    setRowCount(responseObject.totalCount);
                    setLoading(false);
                }
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                }

                setLoading(false);
                console.error(error);
                setOpenError(true);     // Show the erorr Notification
            }
        }

        getModuleRows();
    }, [instance, accounts, inProgress, apiData, page, pageSize, filterModuleId, filterConnectorName, filterDeviceName, setOpenError]);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: 2.5 }}>
                <Typography role="heading" variant="h6" noWrap component="div">
                    Connectors
                </Typography>
                <Button variant="contained" size="small" onClick={handleCreateModule} disabled={!isAuthorized}>
                    + CREATE MODULE
                </Button>
            </Box>
            <Box sx={{ height: 670, width: '100%' }}>
                <DataGridPro
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize);
                        setApiData(null); // Trigger useEffect to get data for new pageSize
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    rows={apiData ?? []}
                    columns={columns}
                    components={{ LoadingOverlay: LinearProgress, Toolbar: GridToolbar }}
                    loading={loading}
                    onRowDoubleClick={(rowData) => handleRowDoubleClick(rowData.row)}
                    onRowClick={(rowData) => handleRowClick(rowData.row)}
                    onPageChange={(newPage) => {
                        setPage(++newPage); // Datagrid page start with 0 while api page start with 1
                        setApiData(null); // Trigger useEffect to get data for new page
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(newFilterModel) => {
                        // Note that most filter operations are applied after the data is retrieved from the API. Therefore, only a subset of data is displayed in the grid (after the filter is applied). 
                        // For ‘contains’ filter operations applied on the 'name' and 'deviceName' columns, the filter value is passed to the API but then the built-in filter is applied on the client side.
                        // This can result in unusual behavior if the API and Client-side filters do not work in the same way. Given that the API filter works like 'contains' operation,
                        // the contains filter is used to map to the API filter below
                        const name = newFilterModel.items.find(idx => idx.columnField === 'name' && idx.operatorValue === 'contains')?.value;     // API Filter by connector name
                        setFilterConnectorName(name);

                        const deviceName = newFilterModel.items.find(idx => idx.columnField === 'deviceName' && idx.operatorValue === 'contains')?.value;     // API Filter by device name
                        setFilterDeviceName(deviceName);

                        setFilterModel(newFilterModel);
                        setFilterModuleId(null);
                        setApiData(null);
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide specified columns, the other columns will remain visible
                                id: false,
                                statusMessage: false,
                            },
                        },
                    }}
                />
                <ConnectorDialog
                    connector={selectedConnector}
                    open={openEditDialog}
                    closeHandler={closeDialogHandler}
                    onConfirm={saveHandler}
                    setOpenError={setOpenError}
                />
                <DeploymentDialogCreateDeployment
                    open={openDialogCreateDeployment}
                    closeHandler={handleCloseDialogCreateDeployment}
                    onConfirm={saveHandler}
                    connector={selectedConnector}
                    setOpenError={setOpenError}
                />
                <ConnectorCreateModule
                    open={openDialogCreateModule}
                    closeHandler={handleCloseDialogCreateModule}
                    onConfirm={saveHandler}
                    setOpenError={setOpenError}
                />
            </Box>
        </>
    );
}