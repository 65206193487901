const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID || window.env.AUTH_CLIENT_ID;
const authAuthority = process.env.REACT_APP_AUTH_AUTHORITY || window.env.AUTH_AUTHORITY;
const authRedirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI || window.env.AUTH_REDIRECT_URI;
const authPostLogoutRedirectUri = process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI || window.env.AUTH_POST_LOGOUT_REDIRECT_URI;

export const msalConfig = {
    auth: {
        clientId: authClientId,
        authority: authAuthority,
        redirectUri: authRedirectUri,
        postLogoutRedirectUri: authPostLogoutRedirectUri,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: [`api://${authClientId}/Deployment.Dashboard.All`],
};