import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { canRead } from './Authorized';
import PersistentDrawer from './PersistentDrawer';

function App() {
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress, accounts } = useMsal();

    if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect(loginRequest);
    }

    const isAuthorized = canRead(accounts);

    return (
        <>
            {isAuthorized ? (
                <AuthenticatedTemplate>
                    <PersistentDrawer />
                </AuthenticatedTemplate>
            ) : (
                <div>You have not been granted access to this website. Please contact your people manager to arrange access.</div>
            )}
            <UnauthenticatedTemplate>
                <div>Redirecting...</div>
            </UnauthenticatedTemplate>
        </>
    );
}

export default App;
