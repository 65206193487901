import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Chip, ChipProps, LinearProgress, Menu, MenuItem, Typography } from '@mui/material';
import {
    DataGridPro,
    GridColDef,
    GridFilterModel,
    GridMoreVertIcon,
    GridRenderCellParams,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { loginRequest } from '../authConfig';
import { callGetDeploymentManifests, callGetDeploymentsSearch } from '../services/dashboardService';
import { Connector } from '../types/Connector';
import { DeploymentRecord } from '../types/DeploymentRecord';
import { checkAuthorization } from './Authorized';
import DeploymentDialogCreateDeployment from './DeploymentDialogCreateDeployment';

export default function DeploymentDataGrid(props: { setOpenError: (open: boolean) => void; }) {
    const { setOpenError } = props;

    function getChipProps(params: GridRenderCellParams): ChipProps {
        switch (params.value) {
            case "Pending":
                return {
                    label: "Pending",
                    color: "warning"
                };
            case "InProgress":
                return {
                    label: "InProgress",
                    color: "primary"
                };
            case "Succeeded":
                return {
                    label: "Succeeded",
                    color: "success"
                };
            case "Failed":
                return {
                    label: "Failed",
                    color: "error"
                };
            default:
                return {
                    label: params.value
                };
        }
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Deployment ID', width: 300 },
        { field: 'name', headerName: 'Deployment Name' },
        { field: 'moduleId', headerName: 'Module ID' },
        { field: 'moduleName', headerName: 'Module Name', width: 250 },
        { field: 'deviceName', headerName: 'Device Name', width: 150 },
        { field: 'moduleType', headerName: 'Application Type', width: 150 },
        { field: 'version', headerName: 'Version', width: 75 },
        {
            field: 'dateTimeCreated', headerName: 'Date Created', width: 200, type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value).toISOString().slice(0, -5),
        },
        {
            field: 'dateTimeApplied', headerName: 'Date Applied', width: 200, type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value).toISOString().slice(0, -5),
        },
        { field: 'assignedBy', headerName: 'Assigned By', width: 200 },
        {
            field: 'status', headerName: 'Status', width: 100, renderCell: (params) => {
                return <Chip size="small" {...getChipProps(params)} />;
            }
        },
        { field: 'statusMessage', headerName: 'Status Message' },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            filterable: false,
            width: 75,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            id="deployment-actions-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            startIcon={<GridMoreVertIcon />}
                        />
                        <Menu
                            id="deployment-actions-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'deployment-actions-button-item',
                            }}
                        >
                            <MenuItem onClick={handleDownloadManifestActionClick} disabled={!isAuthorized}>Download Manifest</MenuItem>
                            <MenuItem onClick={handleViewDeploymentsActionClick}>View Connector</MenuItem>
                        </Menu>
                    </Box>
                );
            }
        }
    ];

    // Extract filter module from state if any
    // This filter can be passed from Connectors view
    const { state } = useLocation();
    const filterModule = state as Connector;
    const defaultFilter = state
        ? {
            items: [
                {
                    columnField: 'moduleId',
                    operatorValue: 'equals',
                    value: filterModule?.id,
                },
            ],
        }
        : undefined;

    const [searchParams] = useSearchParams();
    const deviceSearch = searchParams.get('deviceName') || '';

    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(defaultFilter);
    const [filterDeviceName, setFilterDeviceName] = useState<string>(deviceSearch);

    const { instance, inProgress, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState<string>('');
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [rowCount, setRowCount] = useState<number>(0);
    const [moduleId, setModuleId] = useState<string | null>(filterModule?.id);
    const [apiData, setApiData] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialogCreateDeployment, setOpenDialogCreateDeployment] = useState<boolean>(false);
    const [selectedDeployment, setSelectedDeployment] = useState<DeploymentRecord>({} as DeploymentRecord);

    const navigate = useNavigate();

    const handleRowClick = (rowData: DeploymentRecord) => {
        setSelectedDeployment({ ...rowData });
    };

    const handleViewDeploymentsActionClick = () => {
        navigate("/", { state: selectedDeployment });
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadManifestActionClick = async () => {
        handleClose();

        try {
            const response = await callGetDeploymentManifests(accessToken, selectedDeployment?.id);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', selectedDeployment?.id + `.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error: any) {
            console.error(error.message);
            setOpenError(true);     // Show the erorr Notification
        }
    };

    const handleCreateDeployment = () => {
        setOpenDialogCreateDeployment(true);
    };

    const handleCloseDialogCreateDeployment = useCallback(() => {
        setOpenDialogCreateDeployment(false);
    }, []);

    const handleSaveCreateDeployment = useCallback((change: boolean) => {
        if (change) {
            // To trigger data grid refresh after saved
            setApiData(null);
        }
    }, []);

    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        const getDeploymentRows = async () => {
            // Already loaded or loading
            if (apiData || inProgress !== InteractionStatus.None) {
                return;
            }

            const accessTokenRequest = {
                scopes: loginRequest.scopes,
                account: accounts[0],
            };

            try {
                setLoading(true);

                checkAuthorization(setIsAuthorized, accounts);

                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
                // Acquire token silent success
                const newAccessToken = accessTokenResponse.accessToken;
                setAccessToken(newAccessToken);

                // Call your API with token
                const response = await callGetDeploymentsSearch(newAccessToken, pageSize, page, moduleId, filterDeviceName);
                const responseObject = response.data;
                setApiData(responseObject.items);
                setRowCount(responseObject.totalCount);
                setLoading(false);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    // Acquire token interactive
                    instance.acquireTokenRedirect(accessTokenRequest);
                }

                setLoading(false);
                console.error(error);
                setOpenError(true);     // Show the erorr Notification
            }
        }

        getDeploymentRows();
    }, [instance, accounts, inProgress, apiData, page, pageSize, moduleId, filterDeviceName, setOpenError]);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: 2.5 }}>
                <Typography role="heading" variant="h6" noWrap component="div">
                    Deployments
                </Typography>
                <Button variant="contained" size="small" onClick={handleCreateDeployment} disabled={!isAuthorized}>
                    + CREATE DEPLOYMENT
                </Button>
            </Box>
            <Box sx={{ height: 670, width: '100%' }}>
                <DataGridPro
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize);
                        setApiData(null); // Trigger useEffect to get data for new pageSize
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    rows={apiData ?? []}
                    columns={columns}
                    components={{ LoadingOverlay: LinearProgress, Toolbar: GridToolbar }}
                    loading={loading}
                    onPageChange={(newPage) => {
                        setPage(++newPage); // Datagrid page start with 0 while api page start with 1
                        setApiData(null);
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(newFilterModel) => {
                        // Note that most filter operations are applied after the data is retrieved from the API. Therefore, only a subset of data is displayed in the grid (after the filter is applied). 
                        // For ‘contains’ filter operations applied on the 'deviceName' column, the filter value is passed to the API but then the built-in filter is applied on the client side.
                        // This can result in unusual behavior if the API and Client-side filters do not work in the same way. Given that the API filter works like 'contains' operation,
                        // the contains filter is used to map to the API filter below
                        const deviceName = newFilterModel.items.find(i => i.columnField === 'deviceName' && i.operatorValue === 'contains')?.value;     // API Filter by device name
                        setFilterDeviceName(deviceName);

                        setFilterModel(newFilterModel);
                        setModuleId(null);
                        setApiData(null);
                    }}
                    onRowClick={(rowData) => handleRowClick(rowData.row)}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide specified columns, the other columns will remain visible
                                name: false,
                                moduleId: false,
                                statusMessage: false
                            },
                        },
                    }}
                />
                <DeploymentDialogCreateDeployment
                    open={openDialogCreateDeployment}
                    closeHandler={handleCloseDialogCreateDeployment}
                    onConfirm={handleSaveCreateDeployment}
                    setOpenError={setOpenError}
                />
            </Box>
        </>
    );
}
