import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { msalConfig } from './authConfig';
import App from './components/App';
import ApplicationTypes from './components/ApplicationTypes';
import Deployer from './components/Deployer';
import Deployments from './components/Deployments';
import './index.css';
import reportWebVitals from './reportWebVitals';

const licenseKey = process.env.REACT_APP_MUI_LICENSE_KEY || window.env.MUI_LICENSE_KEY;
LicenseInfo.setLicenseKey(licenseKey);

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<App />}>
                        <Route path="deployments" element={<Deployments setOpenError={() => null} />} />
                        <Route path="deployer" element={<Deployer setOpenError={() => null} />} />
                        <Route path="types" element={<ApplicationTypes setOpenError={() => null} />} />
                    </ Route>
                </Routes>
            </BrowserRouter>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
