import AddTaskIcon from '@mui/icons-material/AddTask';
import CategoryIcon from '@mui/icons-material/Category';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import MenuIcon from '@mui/icons-material/Menu';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import {
    Box, CssBaseline, Divider, Drawer, IconButton, Link, List, ListItem,
    ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ApplicationTypes from './ApplicationTypes';
import Connectors from './Connectors';
import Deployer from './Deployer';
import Deployments from './Deployments';
import ErrorNotification from './ErrorNotification';
import { SignOutButton } from './SignOutButton';

const drawerWidth = 310;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const StyledList = styled(List)({
    // selected and (selected + hover) states
    '&& .Mui-selected, && .Mui-selected:hover': {
        backgroundColor: '#575757',
        '&, & .MuiListItemIcon-root': {
            color: 'white',
        },
    },
    // hover states
    '& .MuiListItemButton-root:hover': {
        backgroundColor: '#404040',
        '&, & .MuiListItemIcon-root': {
            color: 'white',
        },
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#1976d2',
        },
    },
});

export default function PersistentDrawer() {
    const location = useLocation();
    const [open, setOpen] = React.useState(true);

    const handleToggleDrawer = () => {
        setOpen((o) => !o);
    };

    const [openError, setOpenError] = React.useState(false);
    const errorNotificatinProps = {
        openError,
        setOpenError,
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleToggleDrawer}
                            edge="start"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Willow
                        </Typography>
                        <SignOutButton />
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader />
                    <List sx={{ paddingTop: 2, paddingBottom: 2 }}>
                        <ListItem disablePadding>
                            <ListItemIcon sx={{ paddingLeft: 2, paddingBottom: 0.5 }}>
                                <DeviceHubIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="IoT Connector Management" sx={{ paddingLeft: 2 }} />
                        </ListItem>
                    </List>
                    <Divider />
                    <StyledList>
                        <Link href="/" underline="none" color="inherit">
                            <ListItem disablePadding>
                                <ListItemButton selected={"/" === location.pathname}>
                                    <ListItemIcon sx={{ paddingBottom: 0.5 }}>
                                        <DeviceHubIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Connectors" />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link href="/deployments" underline="none" color="inherit">
                            <ListItem disablePadding>
                                <ListItemButton selected={"/deployments" === location.pathname}>
                                    <ListItemIcon sx={{ paddingBottom: 0.5 }}>
                                        <WorkHistoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Deployments" />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link href="/deployer" underline="none" color="inherit">
                            <ListItem disablePadding>
                                <ListItemButton selected={"/deployer" === location.pathname}>
                                    <ListItemIcon sx={{ paddingBottom: 0.5 }}>
                                        <AddTaskIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Deployer" />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Link href="/types" underline="none" color="inherit">
                            <ListItem disablePadding>
                                <ListItemButton selected={"/types" === location.pathname}>
                                    <ListItemIcon sx={{ paddingBottom: 0.5 }}>
                                        <CategoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Types" />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    </StyledList>
                </Drawer>
                <Main open={open}>
                    <DrawerHeader />
                    <Routes>
                        <Route path="/" element={<Connectors setOpenError={setOpenError} />}>
                        </Route>
                        <Route path="/deployments" element={<Deployments setOpenError={setOpenError} />}>
                        </Route>
                        <Route path="/deployer" element={<Deployer setOpenError={setOpenError} />}>
                        </Route>
                        <Route path="/types" element={<ApplicationTypes setOpenError={setOpenError} />}>
                        </Route>
                    </Routes>
                </Main>
            </ThemeProvider>
            <ErrorNotification {...errorNotificatinProps}></ErrorNotification>
        </Box>
    );
}
