import { AccountInfo } from "@azure/msal-browser";

export function checkAuthorization(
    setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>,
    accounts: AccountInfo[]
) {
    const authorized = accounts[0]?.idTokenClaims?.roles?.includes('Write');

    if (authorized) {
        setIsAuthorized(true);
    }
};

export function canRead(accounts: AccountInfo[]) {
    return !!accounts[0]?.idTokenClaims?.roles?.includes('Read');
}
