import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Box, Button, LinearProgress, Menu, MenuItem, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridMoreVertIcon, GridToolbar } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { loginRequest } from '../authConfig';
import { callGetModuleTypes, callGetModuleTypesSearch } from '../services/dashboardService';
import { ApplicationType } from '../types/ApplicationType';
import ApplicationTypeUploadTemplate from './ApplicationTypeUploadTemplate';
import { checkAuthorization } from './Authorized';

export default function ApplicationTypes(props: { setOpenError: (open: boolean) => void; }) {
    const { setOpenError } = props;

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID' },
        { field: 'name', headerName: 'Application Types', width: 300 },
        { field: 'version', headerName: 'Version', width: 130 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            filterable: false,
            width: 75,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            id="module-types-actions-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            startIcon={<GridMoreVertIcon />}
                        />
                        <Menu
                            id="module-types-actions-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseRowMenu}
                            MenuListProps={{
                                'aria-labelledby': 'types-actions-button-item',
                            }}
                        >
                            <MenuItem onClick={handleDownloadTemplateActionClick}>Download Template</MenuItem>
                            <MenuItem onClick={handleUploadTemplateActionClick} disabled={!isAuthorized}>Upload Template</MenuItem>
                        </Menu>
                    </Box>
                );
            }
        }
    ];

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseRowMenu = () => {
        setAnchorEl(null);
    };

    const handleDownloadTemplateActionClick = async () => {
        handleCloseRowMenu();

        try {
            const response = await callGetModuleTypes(accessToken, selectedApplicationType.name, selectedApplicationType.version);

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${selectedApplicationType.name}_${selectedApplicationType.version}.json`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error: any) {
            console.error(error.message);
            setOpenError(true);     // Show the erorr Notification
        }
    };

    const { instance, inProgress, accounts } = useMsal();
    const [moduleTypes, setModuleTypes] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [rowCount, setRowCount] = useState<number>(0);
    const [accessToken, setAccessToken] = useState<string>('');
    const [openDialogUploadTemplate, setOpenDialogUploadTemplate] = useState<boolean>(false);
    const [selectedApplicationType, setSelectedApplicationType] = useState<ApplicationType>({} as ApplicationType);

    const handleUploadTemplateActionClick = () => {
        handleCloseRowMenu();
        setOpenDialogUploadTemplate(true);
    };

    const handleCloseDialogUploadTemplate = useCallback(() => {
        setOpenDialogUploadTemplate(false);
    }, []);

    const saveHandler = useCallback((change: boolean) => {
        if (change) {
            // To trigger data grid refresh after saved
            setModuleTypes(null);
        }
    }, []);

    const handleRowClick = (rowData: ApplicationType) => {
        setSelectedApplicationType({ ...rowData });
    };

    const handleRowDoubleClick = (rowData: ApplicationType) => {
        setSelectedApplicationType({ ...rowData });
    };

    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        const getData = setTimeout(async () => {
            // Already loaded or loading
            if (moduleTypes || inProgress !== InteractionStatus.None) {
                return;
            }

            const accessTokenRequest = {
                scopes: loginRequest.scopes,
                account: accounts[0],
            };

            try {
                setLoading(true);

                checkAuthorization(setIsAuthorized, accounts);

                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);

                // Acquire token silent success
                const accessToken = accessTokenResponse.accessToken;
                setAccessToken(accessToken);

                const response = await callGetModuleTypesSearch(accessToken, pageSize, page);
                const responseObject = response.data;
                const types = responseObject.items as [];
                let map = types.map((value: any, index) => {
                    return { id: index, name: value.moduleType, version: value.latestVersion };
                });

                setModuleTypes(map);
                setRowCount(responseObject.totalCount);
                setLoading(false);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                }

                console.log(error);
                setOpenError(true);     // Show the erorr Notification
                setLoading(false);
            };
        }, 250);

        return () => clearTimeout(getData);
    }, [instance, accounts, inProgress, moduleTypes, page, pageSize, setOpenError]);

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: 2.5 }}>
                <Typography role="heading" variant="h6" noWrap component="div">
                    Types
                </Typography>
            </Box>
            <Box sx={{ height: 670 }}>
                <DataGridPro
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => {
                        setPageSize(newPageSize);
                        setModuleTypes(null); // Trigger useEffect to get data for new pageSize
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    rows={moduleTypes ?? []}
                    columns={columns}
                    components={{ LoadingOverlay: LinearProgress, Toolbar: GridToolbar }}
                    loading={loading}
                    onRowDoubleClick={(rowData) => handleRowDoubleClick(rowData.row)}
                    onRowClick={(rowData) => handleRowClick(rowData.row)}
                    onPageChange={(newPage) => {
                        setPage(++newPage); // Datagrid page start with 0 while api page start with 1
                        setModuleTypes(null); // Trigger useEffect to get data for new page
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide specified columns, the other columns will remain visible
                                id: false
                            },
                        },
                    }}
                />
                <ApplicationTypeUploadTemplate
                    open={openDialogUploadTemplate}
                    closeHandler={handleCloseDialogUploadTemplate}
                    onConfirm={saveHandler}
                    moduleType={selectedApplicationType}
                    setOpenError={setOpenError}
                />
            </Box>
        </Box>
    );
}
